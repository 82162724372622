<template>
    <div class="modal fade" id="appStoreModal" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Descarga la nueva versión</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <a class="d-flex flex-wrap justify-content-center"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.devcobaja.enforma"
                    >
                        <img
                            style="max-width: 80%; margin-bottom: 10px;"
                            :src="`../../../assets/images/google_play_download.png`"
                            alt="Download GP"
                        />
                    </a>
                    <a class="d-flex flex-wrap justify-content-center"
                        target="_blank"
                        href="https://apps.apple.com/mx/app/enforma-digital/id6475118700?l=en-GB"
                    >
                        <img
                            style="max-width: 80%;"
                            :src="`../../../assets/images/app_store_download.png`"
                            alt="Download AS"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
    <a id="appStoreModalBtn"
        role="button"
        class="d-none" 
        data-target="#appStoreModal"
        data-toggle="modal"
    ></a>
</template>

<script>
import { computed, onMounted } from 'vue';
export default {
    setup() {
        onMounted(()=>{
           if(window.localStorage.getItem('appStoreModalDate') != new Date().toDateString()){
                setTimeout(()=>{
                    window.localStorage.setItem('appStoreModalDate', new Date().toDateString())    
                    document.getElementById('appStoreModalBtn')?.click();
                }, 2000)
            }
        })
        return {
        }
    },
}
</script>