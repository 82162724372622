export default function useUser(){

    const getProfilePhoto = (user) => {
        if(user?.profile_photo_path)
        return `${process.env.VUE_APP_API_GATEWAY}/storage/clientes/${user.profile_photo_path}`
        else
        return user?.profile_photo_url
    }

    const checkClientType = (type) => {
        if(!type) return;
        const typeLower = type.toLowerCase()
        const types = {
            normal: {
                name: 'Normal',
                color: 'badge-primary'
            },
            premium: {
                name: 'Premium',
                color: 'badge-secondary'
            }
        }
        return types[typeLower]
    }

    return {
        getProfilePhoto,
        checkClientType
    }
}
