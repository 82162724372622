import { ref } from "@vue/reactivity";
import { logoutService } from "../../services/auth/authServices";
import useSesionToken from "./useSesionToken";
export default () => {
    const { deleteSesionToken } = useSesionToken();

    const loading = ref(false);

    const logout = async () => {
        loading.value = true;

        const response = await logoutService();

        if (response.status === 200) {
            deleteSesionToken();
        }

        loading.value = false;

        return response;
    };

    return { loading, logout };
};
