import { SET_USER, SET_SESION } from "./Types";

const User = {
    namespaced: true,
    state: () => ({
        user: null,
        sesion: false,
    }),
    mutations: {
        [SET_USER](state, user) {
            state.user = user;
        },
        [SET_SESION](state, sesion) {
            state.sesion = sesion;
        },
    },
    actions: {
        setUser(ctx, usuario) {
            ctx.commit(SET_USER, usuario);
        },
        login(ctx) {
            ctx.commit(SET_SESION, true);
        },
        logout(ctx) {
            ctx.commit(SET_SESION, false);
            ctx.commit(SET_USER, {
                name: "",
                email: "",
                password: "",
                confirm_password: "",
                profile_photo_url: "",
                client: {
                    facebook: "",
                    phone_number: "",
                    cell_phone_number: "",
                    calle: "",
                    numero: "",
                    colonia: "",
                    birthdate: "",
                    birthplace: "",
                    civil_status: "",
                    occupation: "",
                    height: "",
                    body_weight: "",
                    blood_type: "",
                    start_date: "",
                    activation_type: "",
                    find_out_gym: "",
                    communicate_with: "",
                    extra_phone_number: "",
                    client_type_id: "",
                    invoice: 0,
                    padecimientos: [
                        {
                            name: "",
                        },
                    ],
                },
            });
        },
    },
};

export default User;
