/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                "App is being served from cache by a service worker.\n" +
                    "For more details, visit https://goo.gl/AFskqB"
            );
        },
        registered(registration) {
            console.log("Service worker has been registered.");
        },
        cached() {
            console.log("Content has been cached for offline use.");
        },
        updatefound(registration) {
            console.log("New content is downloading.");
        },
        updated(registration) {
            console.log("New content is available; please refresh.");
            let worker = registration.waiting;
            Swal.fire({
                title: "Nuevo contenido disponible!",
                text: "Quieres recargar la aplicación ahora mismo?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí",
                cancelButtonText: "No",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    worker.postMessage({ action: "skipWaiting" });
                }
            });
        },
        offline() {
            console.log(
                "No internet connection found. App is running in offline mode."
            );
        },
        error(error) {
            console.error("Error during service worker registration:", error);
        },
    });

    let refreshing;
    navigator.serviceWorker.addEventListener("controllerchange", (e) => {
        if (refreshing) return;
        window.location.reload();
        refreshing = true;
    });
}
