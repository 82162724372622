<template>
    <div class="banner-container shadow-lg" v-if="deferredPrompt">
        <div class="btn-close">
            <button type="button" class="close" @click="dismiss">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="row">
            <div class="col-4">
                <img
                    class="banner-logo"
                    :src="`${base_url_api}/assets/images/Perfil_Blanco.jpg`"
                    alt="Logo Enforma"
                />
            </div>
            <div class="col-8">
                <div class="d-flex align-items-center h-100">
                    <a
                        href="#"
                        class="text-primary"
                        @click="install"
                    >Agrega Enforma a la pantalla principal</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { ref } from '@vue/reactivity';
import Cookies from "js-cookie";
import { COOKIE_HOME_SCREEN } from "./utils/constantes";
export default {
    setup() {
        const deferredPrompt = ref(null)

        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault();
            // Guardamos el evento para utilizarlo despues
            if (Cookies.get(COOKIE_HOME_SCREEN) === undefined) {
                setTimeout(() => {
                    deferredPrompt.value = e;
                }, 15000);
            }
        });

        window.addEventListener("appinstalled", () => {
            deferredPrompt.value = null;
        });


        const dismiss = () => {
            Cookies.set(COOKIE_HOME_SCREEN, null, { expires: 15 });
            deferredPrompt.value = null;
        }

        const install = () => {
            deferredPrompt.value.prompt();
        }

        return {
            deferredPrompt,
            dismiss,
            install,
            base_url_api: computed(() => process.env.VUE_APP_API_GATEWAY)
        }
    },
}
</script>

<style scoped>
.banner-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background: #fff;
    max-height: 100px;
    z-index: 9999;
}

.banner-logo {
    width: 100%;
    height: 100%;
    max-width: 75px;
    max-height: 75px;
}

.btn-close {
    position: absolute;
    z-index: 99999;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
</style>
