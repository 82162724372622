<template>
    <div class="page-titles d-flex align-items-center">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a href="#" @click.prevent="handleClick('/perfil')">
                    <i class="fa fa-home"></i>
                </a>
            </li>
            <li
                v-for="(item, index) in items"
                :key="index"
                class="breadcrumb-item active"
                aria-current="page"
            >
                <a href="#" @click.prevent="handleClick(item.path)">{{ item.text }}</a>
            </li>
        </ol>
        <template v-if="add_button">
            <div class="navbar-nav flex-row ml-auto float-right">
                <div class="title-action pr-4">
                    <button
                        class="btn btn-primary btn-sm text-white"
                        @click="add()"
                    >
                        <i class="fa fa-plus"></i>
                        Añadir registro
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
    props: {
        items: {
            type: Array,
            default: []
        },
        add_button: {
            type: Boolean,
            default: false
        }
    },
    emtis: ['add'],
    setup(props, {emit}) {
        // dinamic bread links
        const router = useRouter()
        const handleClick = (path) => {
            if (path) router.push(path)
        }

        const add = () => {
            emit('add')
        }

        return {
            add,
            handleClick,
        }
    }
}
</script>

<style scoped>
.breadcrumb-item {
    color: #6cc51d !important;
}

.breadcrumb-item a {
    color: #6cc51d !important;
}

.breadcrumb-item + .breadcrumb-item::before {
    color: #6cc51d !important;
}

.page-titles {
    background-color: #ddf7c7;
}
</style>
