const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
    meta: { requiredAuth: false }
  },
  {
    path: '/reset-password/:token/:email',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/resetPassword/ResetPassword.vue'),
    meta: { requiredAuth: false }
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Profile.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/perfil/editar',
    name: 'PerfilEditar',
    component: () => import(/* webpackChunkName: "profileEdit" */ '../views/profile/ProfileEdit.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/asistencias',
    name: 'Asistencias',
    component: () => import(/* webpackChunkName: "attendances" */ '../views/attendances/Attendances.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/entrenamientos',
    name: 'Entrenamientos',
    component: () => import(/* webpackChunkName: "entrenamientos" */ '../views/routines/Routines.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/retos',
    name: 'Retos',
    component: () => import(/* webpackChunkName: "challenges" */ '../views/challenges/Challenges.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/pagos',
    name: 'Pagos',
    component: () => import(/* webpackChunkName: "payments" */ '../views/payments/Payments.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/notas',
    name: 'Notas',
    component: () => import(/* webpackChunkName: "notes" */ '../views/notes/Notes.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/cuestionarios',
    name: 'Cuestionarios',
    component: () => import(/* webpackChunkName: "Questionnaires" */ '../views/questionnaires/Questionnaires.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/tienda',
    name: 'Tienda',
    component: () => import(/* webpackChunkName: "store" */ '../views/store/Store.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/carrito',
    name: 'Carrito',
    component: () => import(/* webpackChunkName: "cart" */ '../views/cart/Cart.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/evaluaciones',
    name: 'Evaluaciones',
    component: () => import(/* webpackChunkName: "Evaluations" */ '../views/evaluations/Evaluations.vue'),
    meta: { requiredAuth: true }
  },
]

export default routes
