<template>
    <c-show-if-sesion v-slot="{ sesion }">
        <div id="main-wrapper" :class="[{ 'menu-toggle': sidebar }, {'vh-100' : !sesion}]">
            <template v-if="sesion">
                <nav-header-layout></nav-header-layout>
                <nav-layout></nav-layout>
                <sidebar-layout></sidebar-layout>
            </template>

            <!--  BEGIN MAIN CONTAINER  -->
            <div :class="sesion ? 'content-body' : 'content-wrapper'">
                <!--  BEGIN CONTENT PART  -->

                <div :class="`container${sesion ? '-fluid' : ''}`">
                    <slot></slot>
                </div>

                <!--  END CONTENT PART  -->
            </div>
            <footer-layout v-if="sesion"></footer-layout>
            <!-- END MAIN CONTAINER -->
        </div>
    </c-show-if-sesion>

    <!-- MODAL WRAPPER -->
    <div id="modal-wrapper"></div>
    <!-- END MODAL WRAPPER -->
</template>

<script>
import NavLayout from './NavLayout.vue'
import NavHeaderLayout from './NavHeaderLayout.vue'
import BreadLayout from './BreadLayout.vue'
import SidebarLayout from './SidebarLayout.vue'
import FooterLayout from './FooterLayout.vue'
import { ref } from '@vue/reactivity';
import CShowIfSesion from '../components/auth/CShowIfSesion.vue'
import { provide } from '@vue/runtime-core'
export default {
    components: {
        NavLayout,
        NavHeaderLayout,
        BreadLayout,
        SidebarLayout,
        FooterLayout,
        CShowIfSesion,
    },
    setup() {
        // toggle sidebar
        const sidebar = ref(false)
        provide('sidebar', sidebar)
        return {
            sidebar,
        }
    },

}
</script>

<style>
#main-wrapper {
    opacity: 1 !important;
    background-color: #ddf7c7;
}

.content-body {
    min-height: 1100px;
}

.content-wrapper {
    margin-left: 0;
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 0px !important;
}
</style>
