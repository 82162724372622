<template>
    <div class="footer">
        <div class="copyright">
            <p>
                Copyright © ENFORMA &amp; Developed by
                <a href="https://devcobaja.com/" target="_blank">DevcoBaja</a> {{ getCurrentYear() }}
            </p>
        </div>
    </div>
</template>

<script>
import { getCurrentYear } from '../utils/dates.js'
export default {
    setup() {
        return {
            getCurrentYear
        }
    }
}
</script>

<style>
</style>
