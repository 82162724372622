<template>
    <div class="nav-header">
        <a href="#" class="brand-logo p-2">
            <img class="mx-auto" src="/assets/images/Logo_Negro_amarillo.png" alt="Logo" width="100%" />
        </a>

        <div class="nav-control c-nav-control" @click.prevent="toggleSidebar">
            <div :class="['hamburger', { 'is-active': sidebar }]">
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from '@vue/runtime-core'
export default {
    setup() {
        const sidebar = inject('sidebar')
        const toggleSidebar = () => {
            sidebar.value = !sidebar.value
        }
        return {
            sidebar,
            toggleSidebar,
        }
    }

}
</script>

<style scoped>
img {
    width: 100%;
}

.c-nav-control {
    right: -2.2rem;
}
</style>
