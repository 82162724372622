<template>
    <div class="header">
        <div class="header-content">
            <nav class="navbar navbar-expand">
                <div class="collapse navbar-collapse justify-content-end">
                    <ul class="navbar-nav header-right">
                        <li class="nav-item dropdown header-profile">
                            <a class="nav-link" href="#" role="button" data-toggle="dropdown">
                                <img :src="getProfilePhoto(user)" width="20" alt />
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <button
                                    @click.prevent="submitLogout"
                                    class="dropdown-item ai-icon"
                                >
                                    <svg
                                        id="icon-logout"
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="text-danger"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                                        <polyline points="16 17 21 12 16 7" />
                                        <line x1="21" y1="12" x2="9" y2="12" />
                                    </svg>
                                    <span class="ml-2" >Cerrar sesión</span>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import { computed } from '@vue/reactivity';
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useLogout from "../composables/auth/useLogout";
import useUser from '../composables/auth/useUser.js'
export default {
    setup() {
        const store = useStore();
        const router = useRouter();

        const { logout } = useLogout()

        const submitLogout = async () => {
            const res = await logout()
            if(res.status === 200) router.push("/");
        }

        const { getProfilePhoto } = useUser()

        return {
            user: computed(() => store.state.User?.user),
            submitLogout,
            getProfilePhoto
        }
    }


}
</script>

<style>
</style>
