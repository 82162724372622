<template>
    <div class="deznav">
        <div class="deznav-scroll">
            <ul class="metismenu" id="menu">
                <li :class="routeIsActive(r.name)" v-for="(r, index) in routes" :key="index" @click="closeSidebar">
                    <router-link :to="r.route" class="ai-icon" aria-expanded="false">
                        <i :class="['fa', r.icon]"></i>
                        <span class="nav-text">{{ r.name }}</span>
                    </router-link>
                </li>
                <li @click="submitLogout">
                    <a href="#" class="ai-icon">
                        <i class="fa fa-power-off"></i>
                        <span class="nav-text">Cerrar sesión</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { computed } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { inject } from '@vue/runtime-core'
import useLogout from "@/composables/auth/useLogout";
export default {
    setup() {
        const router = useRouter()
        const currentRoute = computed(() => router.currentRoute.value.name)
        const routeIsActive = (route) => currentRoute.value?.includes(route) ? 'mm-active mm-c-active' : ''

        const routes = [
            {
                name: 'Perfil',
                route: '/perfil',
                icon: 'fa-user-circle'
            },
            {
                name: 'Asistencias',
                route: '/asistencias',
                icon: 'fa-check-square'
            },
            {
                name: 'Entrenamientos',
                route: '/entrenamientos',
                icon: 'fa-user-circle'
            },
            {
                name: 'Notas',
                route: '/notas',
                icon: 'fa-file-text'
            },
            {
                name: 'Retos',
                route: '/retos',
                icon: 'fa-users'
            },
            {
                name: 'Pagos',
                route: '/pagos',
                icon: 'fa-money'
            },
            {
                name: 'Cuestionarios',
                route: '/cuestionarios',
                icon: 'fa-question-circle'
            },
            {
                name: 'Tienda',
                route: '/tienda',
                icon: 'fa fa-shopping-bag'
            },
            {
                name: 'Evaluaciones',
                route: '/evaluaciones',
                icon: "fa fa-book" 
            },
        ]

        const sidebar = inject('sidebar')
        const closeSidebar = () => {
            sidebar.value = false
        }

        // logout
        const { logout } = useLogout()
        const submitLogout = async () => {
            const res = await logout()
            if(res.status === 200) router.push("/");
        }
        return {
            routes,
            routeIsActive,
            closeSidebar,
            submitLogout,
        }
    }

}
</script>

<style scoped>
.mm-c-active {
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    border-top-right-radius: 0.875rem;
    border-bottom-right-radius: 0.875rem;
    margin-right: 10px;
    color: #6cc51d !important;
}

@media (min-width: 1200px) {
    .mm-c-active {
        margin-right: 10px;
    }
}
</style>
