<template>
  <slot :sesion="sesion" />
</template>
<script>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import useSesionToken from "../../composables/auth/useSesionToken";
export default {
  setup() {
    const store = useStore();
    const { loadSesionToken } = useSesionToken();
    loadSesionToken();
    return {
      sesion: computed(() => store.state.User.sesion),
    };
  },
};
</script>
