require("./bootstrap");
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "nprogress/nprogress.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const VueApp = createApp(App);
VueApp.use(store).use(router).use(Toast, {}).mount("#app");
