import peticionHttp from "../peticionHttp";
const service = "api";
// const v = "v1";

export const loginService = async (email, password, usersToken = null) => {
    const headers = {};
    if (usersToken) headers["Accept"] = usersToken;
    let response = await peticionHttp.post(
        `${service}/login`,
        {
            email,
            password,
        },
        {
            headers,
        }
    );
    return response;
};

export const logoutService = async () => {
    const response = await peticionHttp.post(`${service}/logout`);
    return response;
};

export const getCurrentUser = async () => {
    const response = await peticionHttp.get(`${service}/clients/show`);
    return response;
};

export const editCurrentUser = async (newUser) => {
    const response = await peticionHttp.post(
        `${service}/clients/update`,
        newUser
    );
    return response;
};

export const updatePasswordUser = async (payload) => {
    const response = await peticionHttp.post(
        `${service}/clients/update/password`,
        payload
    );
    return response;
};

export const sendPasswordRecoveryPetition = async (payload) => {
    const response = await peticionHttp.post(
        `${service}/forgot-password`,
        payload
    );
    return response;
};

export const passwordRecoveryUpdatePasswordService = async (payload) => {
    const response = await peticionHttp.post(`${service}/new-password`, payload);
    return response;
};
