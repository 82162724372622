import axios from "axios";
import Cookies from "js-cookie";
import { COOKIE_SESION_TKN } from "../utils/constantes";
import store from "../store/index";
import router from "../router/index";

const peticionHttp = axios.create({
    baseURL:process.env.VUE_APP_API_GATEWAY,
});

/**
 * Si existe un tonken, lo manda en la petición http
 */
peticionHttp.interceptors.request.use((config) => {
    const token = Cookies.get(COOKIE_SESION_TKN);
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

peticionHttp.interceptors.response.use(
    (res) => {
        const response = {
            status: res.status,
            data: undefined,
            message: undefined,
        };
        if (res?.config?.responseType === "blob") {
            response.data = res.data;
        } else {
            response.message = res.data.message;
            response.data = res.data.data;
        }
        return response;
    },
    (error) => {
        const response = {
            status: undefined,
            data: undefined,
            message: undefined,
        };  

        if (error.response) {
            //Error fuera de los 200
            const { data, status } = error.response;
            console.log(error.response);
            if (status === 401) {
                Cookies.remove(COOKIE_SESION_TKN);
                store.dispatch("User/logout");
                router.push("/");
            }else if (data?.data === 240) {
                Cookies.remove(COOKIE_SESION_TKN);
                store.dispatch("User/logout");
                router.push("/");
                data.error = data.errors[0];
            }else if(status === 422){
                response.status = status;
                data.error = data.errors[0];
            }else if(status === 407){
                response.status = status;
                data.error = data.errors[0];
            }else if(status === 404){
                response.status = status;
                data.error = data.errors[0]; 
            }
            response.status = status;
            response.message = data.error; 

            if (!response.message) {
                response.message = "Se ha presentado un error interno. Favor de intentarlo más tarde.";
            }
        } else if (error.request) {
            response.message = "Se ha presentado un error de comunicación. Favor de intentarlo más tarde.";
        } else {
            response.message = "Se ha presentado un error interno. Favor de intentarlo más tarde.";
        }
        if (response.message){

            let messagev2 = "No encontramos lo que buscaba!"; 

            if (response.message !=""){
                messagev2 = response.message;
            }

            Swal.fire("Error!", messagev2, "error");
        }
        return response;
    }
);

export default peticionHttp;
