export const getDateYyyyMmDd = (format = "basic") => {
    let date = new Date();
    let offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return format === "extended"
        ? date.toISOString().slice(0, 16)
        : date.toISOString().split("T")[0];
};

export const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
};
