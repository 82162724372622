import { ref } from "@vue/reactivity";
import Cookies from "js-cookie";
import { COOKIE_SESION_TKN } from "../../utils/constantes";
import { useStore } from "vuex";
export default () => {
  const sesionToken = ref("");
  const store = useStore();

  sesionToken.value = Cookies.get(COOKIE_SESION_TKN);

  const loadSesionToken = () => {
    sesionToken.value = Cookies.get(COOKIE_SESION_TKN);
    if (sesionToken.value) store.dispatch("User/login");
  };

  const setSesionToken = (newToken) => {
    const expires = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    console.log(expires);
    Cookies.set(COOKIE_SESION_TKN, newToken, { expires });
    loadSesionToken();
  };

  const deleteSesionToken = () => {
    Cookies.remove(COOKIE_SESION_TKN);
    sesionToken.value = "";
    store.dispatch("User/logout");
  };

  return { sesionToken, loadSesionToken, setSesionToken, deleteSesionToken };
};
